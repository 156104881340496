// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-form-success-js": () => import("./../src/pages/form-success.js" /* webpackChunkName: "component---src-pages-form-success-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-property-investment-company-case-studies-js": () => import("./../src/pages/property-investment-company/case-studies.js" /* webpackChunkName: "component---src-pages-property-investment-company-case-studies-js" */),
  "component---src-pages-search-result-js": () => import("./../src/pages/search-result.js" /* webpackChunkName: "component---src-pages-search-result-js" */),
  "component---src-pages-testimonials-js": () => import("./../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-uk-property-investment-guides-videos-js": () => import("./../src/pages/uk-property-investment-guides-videos.js" /* webpackChunkName: "component---src-pages-uk-property-investment-guides-videos-js" */),
  "component---src-templates-area-js": () => import("./../src/templates/area.js" /* webpackChunkName: "component---src-templates-area-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-guide-download-js": () => import("./../src/templates/guide-download.js" /* webpackChunkName: "component---src-templates-guide-download-js" */),
  "component---src-templates-news-area-js": () => import("./../src/templates/news-area.js" /* webpackChunkName: "component---src-templates-news-area-js" */),
  "component---src-templates-news-category-js": () => import("./../src/templates/news-category.js" /* webpackChunkName: "component---src-templates-news-category-js" */),
  "component---src-templates-news-list-template-js": () => import("./../src/templates/news-list-template.js" /* webpackChunkName: "component---src-templates-news-list-template-js" */),
  "component---src-templates-news-sector-js": () => import("./../src/templates/news-sector.js" /* webpackChunkName: "component---src-templates-news-sector-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-property-js": () => import("./../src/templates/property.js" /* webpackChunkName: "component---src-templates-property-js" */),
  "component---src-templates-sector-js": () => import("./../src/templates/sector.js" /* webpackChunkName: "component---src-templates-sector-js" */),
  "component---src-templates-squeeze-pages-property-page-js": () => import("./../src/templates/squeeze-pages/property-page.js" /* webpackChunkName: "component---src-templates-squeeze-pages-property-page-js" */),
  "component---src-templates-squeeze-pages-sector-site-js": () => import("./../src/templates/squeeze-pages/sector-site.js" /* webpackChunkName: "component---src-templates-squeeze-pages-sector-site-js" */)
}

